//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'
import { ADVERTISING_CAMPAIGN_BRANCH_CODE } from '@/const/branch'
import CityBillet from '~/components/modals/CityBillet'

export default {
  name: 'CityList',
  components: {
    CityBillet,
  },
  props: {
    isCenter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCity: false,
      scrollOptions: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#602B7A',
          size: '12px',
          specifyBorderRadius: '10px',
          minSize: 0.1,
        },
      },
    }
  },
  computed: {
    ...mapState('landings', {
      currentDomainData: (state) => state.currentDomainData,
    }),
    ...mapGetters({
      domains: 'landings/domains',
    }),
    sortDomains() {
      const domains = [...this.domains]
      domains.forEach((item, index) => {
        if (
          item.siteUrl === this.currentDomainData &&
          this.currentDomainData.showInDropdown
        ) {
          domains.splice(index, 1)
          domains.unshift(item)
        }
      })
      return domains
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize, { passive: true })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    isRussianFranchise() {
      return this.$getTextMainSetting('isRussianFranchise')
    },
    isAdvertisingCampaign() {
      return (
        this.$getTextMainSetting('branchCode') ===
        ADVERTISING_CAMPAIGN_BRANCH_CODE
      )
    },
    toggleCity() {
      this.showCity = !this.showCity
      if (this.showCity) {
        this.resize()
        window.addEventListener('click', this.hideClickCity, true)
      }
    },
    hideClickCity(e) {
      e.stopImmediatePropagation()
      if (
        !!e.target.closest('.city') &&
        !e.target.closest('.city__current-city')
      ) {
        return
      }
      window.removeEventListener('click', this.hideClickCity, true)
      this.toggleCity()
    },
    async resize() {
      if (this.showCity) {
        await this.$nextTick()
        const cityList = document.getElementsByClassName('city-list')[0]
        cityList.classList.remove('city-list--right')
        cityList.classList.remove('city-list--left')
        const cityListToRight = cityList.getBoundingClientRect().right
        if (window.innerWidth > cityListToRight) {
          cityList.classList.add('city-list--left')
        } else {
          cityList.classList.add('city-list--right')
        }
      }
    },
  },
}
