//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'CityBillet',
  props: {
    isMobileOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCourse: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFaculty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showRedirect: false,
    }
  },
  computed: {
    ...mapGetters({
      featureEnabled: 'landings/featureEnabled',
      suitableDomainData: 'landings/suitableDomainData',
    }),
    isShow() {
      return !!this.suitableDomainData && this.enabledGeolocation()
    },
  },
  mounted() {
    this.showRedirect =
      localStorage.getItem('geoRedirectModal') === null
        ? true
        : JSON.parse(localStorage.getItem('geoRedirectModal'))
  },
  methods: {
    enabledGeolocation() {
      return this.$getTextSetting('enableUserGeolocation')
    },
    close() {
      localStorage.setItem('geoRedirectModal', false)
      this.showRedirect = JSON.parse(localStorage.getItem('geoRedirectModal'))
    },
  },
}
